.bottomNavContainer .navbar {
  background: transparent linear-gradient(180deg, #065F7C 0%, #014779 100%) 0% 0% no-repeat;
  z-index: 1200;
  padding: 10px 5px;
}

.bottomNavContainer ul li {}
.activetest {
  background-color: yellow;
}
.bottomNavContainer ul li .navLink {
  color: #fff;
  text-decoration: none;
  border: none;
  background-repeat: no-repeat;
  background-position: 15px center;
  padding:10px 15px 10px 42px;
  background-size: 20px;
  background-color: transparent;
  border-radius: 25px;
}
.bottomNavContainer ul li .navLink.active{
  background-color: #04A8AA;
  box-shadow: 5px 5px 5px #00000029;
}
.bottomNavContainer ul li .homeLink{
  background-image:url(../../images/menuIcons/homeIcon.svg);
}
.bottomNavContainer ul li .homeLink.active{
   background-image:url(../../images/menuIcons/homeIconActive.svg);
}
.bottomNavContainer ul li .requestLink{
   background-image:url(../../images/menuIcons/requestIcon.svg);
}
.bottomNavContainer ul li .requestLink.active{
   background-image:url(../../images/menuIcons/requestIconActive.svg);
}
.bottomNavContainer ul li .feedbackLink{
  background-image:url(../../images/menuIcons/feedbackIcon.svg);
}
.bottomNavContainer ul li .feedbackLink.active{
  background-image:url(../../images/menuIcons/feedbackIconActive.svg);
}
.bottomNavContainer ul li .navLink:focus {
  color: #fff;
}

.bottomNavContainer ul li button img {
  width: 18px;
  margin-right: 5px;
}
.homeModalWrapper{background-color: rgba(0,0,0,0.7);}
/* .homeModalWrapper .commentArea{margin-top: 1.5rem;} */
.homeModalWrapper .commentArea .form-control{background-color: #EBEBEB;}
.homeModalWrapper .form-label{font-size: 1rem;color:#212529;font-weight: 400;}
.requestWrapper {padding: 60px 0;}

.requestWrapper .label {
  color: #A2A2A2;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.requestWrapper p {
  color: #1D1D1D;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.requestWrapper p.createdDate {
  color: #6c757d;
    font-size: 0.7rem;
}

.requestWrapper .eventSchedule {
  padding-left: 15px;
  background-image: url(../../images/eventScheduleIcon.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 12px 15px;
  /* margin-top: 0.5rem; */
}

.requestWrapper .eventSchedule img {
  width: 15px;
  display: none;
}
.requestWrapper .red{color: #FF5E3A;}
.requestWrapper .orange{color: #FF9500;}
.requestWrapper .green{color: #76C80E;}
.requestWrapper .blue{color: #1FBBA6;}
.requestWrapper .statCom{}
.requestWrapper .statPend{}
.requestWrapper .cancelBtn{color: #DE2424;
  font-size: 0.7rem;
  border: 1px solid #DE2424;
  padding: 5px;}
.requestWrapper .row.bordered{border-bottom:1px solid #D1D8F5}
.textend{
  text-align-last: center;
}