html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Helper css*/
.overflowHidden {
  overflow: hidden;
}

.rulerContainer {
  margin: 1rem 0;
  margin-bottom: 5rem;
}

.btn-primary {
  background-color: #04A8AA !important;
  padding: 10px 25px !important;
  border-radius: 35px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  border: none !important;
}

.modal-backdrop {
  background: transparent linear-gradient(180deg, #065F7C 0%, #014779 100%) 0% 0% no-repeat;
}

.modal-backdrop.show {
  opacity: 1;
}

/*Helper css ends*/
.mainContainer {
  height: 100%;
}

.siteContainer {
  /* background: transparent linear-gradient(180deg, #065F7C 0%, #014779 100%) 0% 0% no-repeat; */
  min-height: 100%;
  background-image: url(./images/textureBg.jpg);
    /* background-image: -webkit-image-set(url(../../images/textureBg.webp) ); */
    background-position: center;
    background-size: auto;
    padding-top: 60px;
}



.overlay {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #014779;
  opacity: 0.92;
  transition: all 0.3s ease-in-out;
}

.innerMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* background-color: rgba(1,71,119,0.7); */
  background-color: #014779;
  width: 100%;
  height: 100%;
  display: none;
  top: 60px;
}

.menuContent {
  position: absolute;
  top: 20px;
  left: 0;
}

.innerMenu .closeButton {
  width: 75px;
  height: 75px;
  margin: 0 15px 15px auto;
  
}

.innerMenu .closeButton span {
  border: 2px solid #fff;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}


.innerMenu .menuTiles{
  text-align: center;
  min-width: 100px;
  margin-bottom: 15px;
}

.innerMenu .menuTiles .innerMenuIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  padding: 10px;
}

.innerMenu .menuTiles .innerMenuIcon img {
  max-width: 30px;
}

.innerMenu .menuTiles span {
  text-shadow: 2px 3px 7px #00000059;
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: auto;
}

/*Toastify css override*/
.Toastify .Toastify__toast{width: 90%;margin:10px auto 0;border-radius:15px;}
/*Toastify css override ends*/
.form-check{
  position: relative;
}
.dateTimeWrapper{
  position: absolute;
  right: 0;
  top:0;
}
.datePicker{margin-right: 1rem;}
.timePicker{}
.datePicker input, .timePicker input{
  border:0;
  border: 1px solid rgb(4, 168, 170);
  width: 6rem;
  border-radius: 5px;
  padding: 4px 7px;
    height: 28px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
}
.timePicker .rc-time-picker-clear{display: none;}
.rc-time-picker-panel{padding-top: 2rem;}
.grow-wrap textarea{
  width: 100%;
  padding: 5px;
  overflow: hidden;
  border: 0;
  height:auto;
}
