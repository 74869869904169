.loginContainer {
    /* background-image: url(../../images/textureBg.jpg); */
    /* background-image: -webkit-image-set(url(../../images/textureBg.webp) ); */
    /* background-position: center;
    background-size: auto; */
}

.loginContainer .logo {
  
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-bottom: 25px;
 
}
.loginContainer .logo img{border-radius: 50%;width: 100%;}
.loginContainer h1 {
    text-align: center;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #FFFFFF;
    line-height: 1.5;
}

.loginContainer h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #29D0D2;
}

.loginContainer .form-field {
    width: 100%;
    padding: 0 25px;
}

.loginContainer .form-control {
    background-color: transparent;
    border: 0;
    border-radius: .375rem;
    border: 2px solid #CEAA71;
    box-shadow: none !important;
    color: #F7FCFF;
    font-weight: 500;
    font-size: 20px;
   
}
.loginContainer .form-field label{color: #fff;font-size: 22px;font-weight: 500;margin-bottom: 10px;}
.loginContainer .btn{margin-top: 20px;}
.loginContainer h3{color:#D9D9D9;font-size: 15px;}
.usrimg {
    background-image: url("../../images/userNameIcon.png");
    background-repeat: no-repeat;
    background-position: 12px center;
    padding-left: 45px;
  }
.selectRoom{margin: 20px;text-align: center;}
.selectRoom .btn-outline{    padding: 10px 25px;
    border-radius: 25px;
    border: 2px solid #FFFFFF;
    color: #FFFFFF !important;
    margin: 10px 10px;
}
.selectRoom .btn-outline.active{
    background-color:#04A8AA;
    border: 2px solid #04A8AA;
    font-weight: bold;
}
footer{}
footer .navbar{color: #fff;font-size: 0.5rem;background-color: #00070b80;padding: 5px 10px;}
