.topBar {
    background-color: #063141;
    padding: 15px 0;
    height: 60px;
    z-index: 1200;
    position:fixed;
    width: 100%;
    top:0;
    left: 0;
  }
  
  .topBar h1 {
    font-size: 15px;
    color: #CEAA71;
    margin: 0
  }
  
  .topBar .dropdown-toggle {
    background-color: transparent !important;
    color: #CEAA71;
    border-radius: 25px;
    font-size: 14px;
    border: 1px solid #898989;
  }

  .dropdownAlign{
    margin-left: auto;
    padding-right: 10px;
  }
  .logoutBtn{
    display: flex;
    text-decoration: none;
  }
  .logoutBtn i{
    font-size: 18px;
    color: #CEAA71;
  }